<template>
  <page-layout title="Settings" linkDefault="/" :backButton="true">
    <div class="h-14 bg-sky flex justify-between relative align-middle">
      <p class="
            flex-none
            ml-7
            my-5
            font-poppins font-semibold
            text-sm
            leading-6
          ">
        Account
      </p>
    </div>
    <div class="w-full flex-col items-center mt-5">
      <link-tab icon="lock" label="Password" direct="chevron-right" to="https://tutoruu.com/account/update-password"
        routing="external" />
      <link-tab icon="log-out" label="Logout" direct="chevron-right" @click="logout" routing="external" />
      <div class="h-14 bg-sky"></div>
      <link-tab icon="help-circle" label="Help" direct="chevron-right" :to="`/support`" />
    </div>
  </page-layout>
</template>

<script>
import LinkTab from "../../components/LinkTab.vue";
import pageLayout from "../../components/base/pageLayout.vue";
import { mapActions } from "vuex";
export default {
  components: {
    LinkTab,
    pageLayout,
  },
  methods: {
    ...mapActions(["logout"])
  }
}
</script>

